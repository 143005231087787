// Pitch

.pitch {

    position: relative;
    background-repeat: round;

    &.responsive {
        padding-bottom: 58.3%;
        width: 100%;
    }

    &.fill {
        height: 100%;
        width: 100%;
    }

    &.small {
        height: 290px;
        width: 500px;
    }

    &.normal {
        height: 525px;
        width: 900px;
    }

    &.big {
        height: 550px;
        width: 600px;
    }

    .teams {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
    }
}

.team {

    height: 100%;
    width: 80%;
    display: inline;

    &.wide {
        width: 80%;
    }

    &.away {

        margin-left: auto;

        .goalkeeper {
            float: right;
        }

        .lines {
            float: left;
            flex-direction: row-reverse;
        }
    }

    .goalkeeper {
        height: 100%;
        width: 25%;
        float: left;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .lines {

        height: 100%;
        width: 75%;
        float: right;
        display: flex;

        .line {

            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

        }
    }
}

.wrapper {
  width: 100%;
  padding: 20px;
  overflow: hidden;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.cardsContainer {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  animation: scroll 160s linear infinite; /* Increased duration for smoother scroll */
  padding: 10px 0;
  width: max-content; /* Allow container to grow based on content */
}

.cardsContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.cardWrapper {
  width: max-content;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); 
  }
}

.cardSkeleton {
  width: 220px; /* Match PlayerCardMarket width */
  height: 165px; /* Match PlayerCardMarket height */
  background: #d7d5d5;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  animation: pulse 1.5s infinite;
}

.cardTitle {
  height: 20px;
  background: #c6c5c5;
  border-radius: 4px;
  margin-bottom: 10px;
}

.cardContent {
  display: flex;
  gap: 10px;
  height: calc(100% - 30px);
}

.cardImage {
  width: 50%;
  background: #c6c5c5;
  border-radius: 4px;
}

.cardChart {
  width: 50%;
  background: #c6c5c5;
  border-radius: 50%;
}

.pulsingCard {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
} 
@import '../../styles/branding.scss';



.container > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text {
    pointer-events: none;
    justify-content: start !important;
    font-family: 'Kanit', sans-serif;
    line-height: 0px;
    text-transform: uppercase;
  }
  
  .number {
    text-align: center;
    height: 100%;
    color: $brand-1;
  }
  
  .number span {
    display: block;
    font-size: 2rem;
    padding: 0.5rem;
  }
  
  .slopeBegin {
    background: linear-gradient(135deg, $brand-5 10%, $brand-5 100%);
    clip-path: polygon(30% 0, 75% 0, 45% 100%, 0% 100%);

    @media (max-width: 768px) {
        background: linear-gradient(135deg, $brand-5 10%, $brand-5 100%);
        clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
    }
  }
  
  
  .slopeEnd {
    clip-path: polygon(70% 0, 100% 0, 100% 100%, 50% 100%);
  }
  
  .slopeBegin,
  .slopeEnd {
    position: absolute;
    width: 200%;
    height: 100%;
    cursor: pointer;
    left: -20%;
  }
  
  .pink {
    /* background: linear-gradient(to right, deeppink 0%, coral 100%); */
    background: transparent;
  }
  
  .teal {
    /* background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%); */
    background: transparent;
  }
  
  .tomato {
    /* background: linear-gradient(to right, tomato 0%, gold 100%); */
    background: transparent;
  }
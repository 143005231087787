.layout-grid {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
  }
  
  .navbar {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 100%;
  }
  
  .login-banner {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    background: #f5f5f5;
    width: 100%;
  }
  
  .content-area {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    // padding: 1rem;
    overflow-y: auto;
    background: #ffffff;
  }

.layout-grid.full-screen {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.layout-grid.full-screen .content-area {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

@media (max-width: 768px) {
  .layout-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
  }

  .navbar {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: block;
  }

  .login-banner {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .content-area {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
}
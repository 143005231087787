.accordion-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

.accordion-toggle {
    z-index: 0; /* 2 */
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    width: 160px; 
    height: 73px; 
    display: inline-block;
    text-align: center;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    position: absolute;
    top: 0px;
    box-shadow: 0 1px 1px #fff, 0 1px 2px #fff, 0 1px 2px #000803, 0 1px 2px #000803, 0 1px 2px #000803;
    .box {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
}

.accordion-toggle:focus {
    outline: none;
}
  
.accordion-toggle.slide-out {
    animation: slide-out 1.2s ease-in-out forwards;
}

.accordion-toggle.open {
    top: 220px;
    @media (max-width: 600px) {
        top: 360px; /* Double the height on mobile devices */
    }
}

.accordion-toggle.slide-up {
    animation: slide-up 0.8s ease-in-out forwards;
}

/* Arrow & Hover Animation */
#more-arrows {
    width: 35px;
    height: 35px;
  }
  
  #more-arrows:hover polygon {
    fill: #0b0b0b;
    transition: all .2s ease-out;
  }
  
  #more-arrows:hover polygon.arrow-bottom {
    transform: translateY(-7px) scale(0.4);
  }
  
  #more-arrows:hover polygon.arrow-top {
    transform: translateY(7px) scale(0.4);
  }

  #more-arrows.animate {
    transition: transform 0.2s ease-out 0.2s;
    transform: rotate(180deg);
  }

  #more-arrows.animate polygon.arrow-bottom {
    transform: translateY(-7px) scale(0.4);
  }
  
  #more-arrows.animate polygon.arrow-top {
    transform: translateY(7px) scale(0.4);
  }

#more-arrows-bottom {
    width: 35px;
    height: 35px;
    transform: rotate(180deg);
  & {
    
    polygon {
      fill: #0b0b0b;
      transition: all .2s ease-out;

      &.arrow-bottom {
        transform: translateY(-7px) scale(0.4);
      }

      &.arrow-top {
        transform: translateY(7px) scale(0.4);
      }
      
    }
  
  }
  
}

#more-arrows-bottom.animate {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
}

polygon {
  fill: #020202;
  transition: all .2s ease-out;
    
  &.arrow-middle {
	  opacity: 0.75;
	}

	&.arrow-top {
	  opacity: 0.5
	}
	 
}

.accordion-content {
    background-color: #fff;
    width: 90vw;
    max-width: 800px;
    height: 220px;
    padding: 10px;
    border-top: 1px solid #ddd;
    box-shadow: 0 2px 2px #fff, 0 2px 5px #fff, 0 2px 5px #000803, 0 2px 4px #000803, 0 2px 5px #000803;
    .box {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
    @media (max-width: 600px) {
        height: 360px; /* Double the height on mobile devices */
    }
}

.slide-down {
    animation: slideDown 0.6s ease-in-out;
}

.slide-up {
    animation: slideUp 0.4s ease-in-out;
}

.fade-out {
    animation: fadeOut 0.3s ease-in-out;
  }
  
@keyframes slide-out {
    0% {
      transform: translateY(0);
    }
    50%, 100% {
      transform: translateY(220px);
    }
}

@keyframes slide-up {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50%, 100% {
      transform: translateY(-220px);
    }
    100% {
      opacity: 0;
    }
  }
  

@keyframes slideDown {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 220px;
      opacity: 1;
    }
}

@keyframes slideUp {
    0% {
        height: 220px;
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
.team-name-button {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 2px 6px;
    border-radius: 4px;
    transition: border-color 0.2s ease;
  }
  
  .team-name-button:hover {
    border-color: #ccc;
  }
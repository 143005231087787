/* LeagueSelectionCard.css */

.z-index-3 {
    color: white;
    z-index: 3; /* Ensure the title is above the gradient */
}

.sub-title-style {
    font-size: 1rem;
    color: white;
    z-index: 3; /* Ensure the subtitle is above the gradient */
}

.img-style {
    font-size: 1rem;
    color: white;
    z-index: 2; 
    width: 85%;
    height: 85%;
}

.hidden-text-style {
    font-size: 1rem;
    color: white;
    z-index: 3;
}

.large-hidden-text-style {
    font-size: 2.5rem;
    color: white;
    z-index: 3; 
}

.small-hidden-text-style {
    font-size: 1.6rem;
    color: white;
    z-index: 3; 
}

.button-style-1 {
    position: 'absolute';
    bottom: '10px';
    width: '100%';
    border-radius: 0.5rem;
    margin: 1rem 0;
    background-color: rgb(250, 255, 249);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    border: 1px solid var(--button-color);
    color: var(--button-text-color);
    font-size: 1rem;
  
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    bottom: 2%; /* Position the button at the bottom of the card with a small margin */
    left: 30%;
    z-index: 3; /* Ensure the button is above the gradient */
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-style-1:hover {
    box-shadow: 0 10px 15px #abf8be, 0 6px 4px rgba(26, 7, 82, 0.22);
}

.button-style-1:active {
    background: white;
    transform: translateY(2px);
}

.brand-shadow {
    box-shadow: 0 10px 15px #abf8be, 0 6px 4px rgba(26, 7, 82, 0.22);
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%; 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 100%);
    z-index: 1; 
    pointer-events: none;
}

.unavailable {
    background-color: grey;
    cursor: not-allowed;
}

.unavailable:hover {
    box-shadow: none;
}

.first {
    width: 500px;
    height: 250px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

/* Add this media query for mobile devices */
@media (max-width: 768px) {
    .first {
        width: 100%;
    }
}

.image-style {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; 
}

.base {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.first:hover {
    transform: translateY(-10px) rotateX(0deg) rotateY(-5deg);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
}

.first:hover .gradient-overlay {
    height: 100%; 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.2) 100%);
}

.first:hover .img-style {
    z-index: 0;
}

.first:hover .hidden-on-load {
    opacity: 1;
}

.card-selected {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7cff01, 0 0 20px #7cff01, 0 0 25px #7cff01 !important;
}

.hidden-on-load {
    /* position: absolute; */
    opacity: 1;
    transition: opacity 0.3s ease;
}

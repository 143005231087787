/* Deck.module.css */
.deck {
    position: absolute;
    width: 100%;
    height: 500px; 
    will-change: transform;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    touch-action: none;
    overflow: visible; 
    z-index: 1;
  }
  
  .cardImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 30%;
    height: 40%;
  }
  

  
  .cardContent {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%; /* Ensure most of the card is used by the image */
    max-width: 450px;
    max-height: 500px;
    will-change: transform;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px; /* Add padding for spacing */
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  }
  
  .title {
    background-color: white;
    border: 1px solid #e0e0e0;  /* light grey border */
    color: #000;  /* black text */
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    border-radius: 0 0 10px 10px; /* Rounded corners at the bottom */
  }
  
  @media (max-width: 768px) {
    .deck {
      width: 82%; /* Full width on mobile */
      max-width: 100%;
    }

    .cardImage {
      width: 40%;
    }
  
    .cardContent {
      width: 90%;
      max-width: 90%;
      max-height: none; /* Remove max-height restriction */
    }
  
    .title {
      font-size: 16px; /* Slightly larger font for better readability on mobile */
      padding: 10px;
    }
  }
  
  .swipeHint {
    position: fixed;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
  }

  .swipeHintText {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
  
  .path {
    width: 20px;
    height: 20px;
    position: absolute;
    background: rgba(149, 147, 241, 0.5);  /* Made semi-transparent */
    border-radius: 40px;  /* Increased border radius */
    top: 22%;
    left: -10%;
    visibility: hidden;  /* Start hidden */
    animation: swipeDot 2s 0.5s infinite;
  }
  
  .handIcon {
    position: relative;
    background-image: url('https://i.postimg.cc/8556gm60/hand.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 100px;
    transform-origin: 52% 62%;
    animation: swipeHand 2s infinite;
  }
  
  @keyframes swipeHand {
    0% {
      transform: translate(0px) rotate(0deg);
    }
    25% {
      transform: translate(-20px) rotate(-30deg);
    }
    50% {
      transform: translate(20px) rotate(35deg);
    }
    100% {
      transform: translate(0px) rotate(0deg);
    }
  }
  
  @keyframes :global(swipeDot) {
    0% {
        visibility: hidden;
        left: -10%;
        width: 20px;
    }
    12% {
        visibility: visible;
        width: 40px;  /* Expand width for trail effect */
    }
    29% {
        visibility: visible;
        left: 70%;
        width: 20px;  /* Return to original width */
    }
    30% {
        visibility: hidden;
    }
  }

  .sparkle {
    position: absolute;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle, #beec14 0%, transparent 70%);
    border-radius: 50%;
    pointer-events: none;
    animation: sparkleAnim 1s ease-out forwards;
    z-index: 5;
  } 

  @keyframes sparkleAnim {
    0% {
      transform: scale(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: scale(1.5) rotate(180deg);
      opacity: 0;
    }
  }

  .trainedText {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
    z-index: 6;
    animation: fadeInOut 1s ease-in-out;
  }

  @keyframes fadeInOut {
    0% { opacity: 0; transform: translate(-50%, -50%) scale(0.5); }
    50% { opacity: 1; transform: translate(-50%, -50%) scale(1.2); }
    100% { opacity: 0; transform: translate(-50%, -50%) scale(1); }
  }

  .levelupChance {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #2c83e1b3;
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: bold;
  }

  .skippedText {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    z-index: 1000;
    animation: fadeOut 1s ease-out;
    pointer-events: none;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.5);
    }
  }
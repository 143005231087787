$cardWidth: 250px;
$cardHeight: 250px;


.subtitle-card {
	position: relative;
	width: $cardWidth * 2.5;
	height: $cardHeight/1.5;
	transform-style: preserve-3d;
	transform: rotatey(0deg) translatex(0px) translatey(0px);
	transition: all 0.6s cubic-bezier(0.680, -0.550, 0.265, 1.550);
	box-shadow: 4px 4px 20px rgba(0,0,0,0.4);
	margin: 5px;
	cursor: pointer;
	background-color: #f5fcfe;
	border: 3px solid black;
	border-radius: 15px;
	font-size: 1.5rem;
	text-align: center;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 3.4rem;
	position: relative;

}

.description-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 3rem;
    font-size: 1.4rem;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0 1.7rem;
    }
}

.subtitle-card-mobile {
	position: relative;
	width: $cardWidth * 1.2;
	height: $cardHeight/1;
	transform-style: preserve-3d;
	transform: rotatey(0deg) translatex(0px) translatey(0px);
	transition: all 0.6s cubic-bezier(0.680, -0.550, 0.265, 1.550);
	box-shadow: 3px 3px 15px rgba(0,0,0,0.4);
	padding: 0 0.4rem;
	margin: 10px;
	cursor: pointer;
	background-color: #f5fcfe;
	border: 2px solid black;
	border-radius: 15px;
	font-size: 1rem;
	text-align: center;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

}
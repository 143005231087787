.list {
  position: relative;
  width: 100%;
  height: 100%;
}

.card {
  position: absolute;
  width: 100%;
  will-change: transform, height, opacity;
  padding: 10px;
}

.cell {
  position: relative;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
}

.details {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s ease-in-out infinite;
}

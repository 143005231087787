.custom-modal {
    position: absolute;
    top: 80%;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 1000;
    right: -20px;

    @media screen and (min-width: 768px) {
        right: -20px;
    }
  }
  
  .custom-modal-content {
    background-color: #fff;
    border-radius: 30px;
    padding: 20px;
    width: 300px;
    margin-right: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: left;
  }
  
  .modal-title {
    font-size: 1rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  
    .modal-wallet-row {
        display: flex;
        align-items: flex-start;
        margin-bottom: 25px;
    }

  .modal-content-row-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-option-title {
    font-weight: bold;
    font-size: 1.1rem;
    color: #333;
  }

  .modal-option-sub-title {
    font-weight: bold;
    font-size: 0.9rem;
    color: #333;
    margin-right: 8px;
    flex-shrink: 0;
  }
  
  .modal-option {
    color: #666;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
  
  /* Custom Logout Button Styling */
  .custom-logout-button {
    display: flex;
    align-items: center;
    background: none;
    color: #333;
    border: none;
    padding-top: 15px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }

    .custom-logout-button:focus {
        outline: none; 
    }
  
  .logout-icon {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  

  .wallet-address {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  }

  .copy-icon {
    margin-left: 8px;
    margin-bottom: 1px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s;
  
    &:hover {
      color: #333;
    }
  }

  .etherscan-icon {
    margin-left: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s;
  
    &:hover {
      color: #333;
    }
  }
@import '../../styles/branding.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  width: 100%;
}

.container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  will-change: width, height;
}

.item {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  will-change: transform, opacity;
}

:export {
  brand3: $brand-3;
}

:export {
  brand1: $brand-1;
}


$maxWidth: 1200px;
$cardWidth: 250px;
$cardHeight: 250px;
$colorMain : #000;
$colorMainDarker: #000;

.element-card {
	position: relative;
	width: $cardWidth;
	height: $cardHeight;
	transform-style: preserve-3d;
	transform: rotatey(0deg) translatex(0px) translatey(0px);
	transition: all 0.6s cubic-bezier(0.680, -0.550, 0.265, 1.550);
	box-shadow: 4px 4px 20px rgba(0,0,0,0.4);
    border: 3px solid black;
	margin: 5px;
	cursor: pointer;
	
	&:hover {
		transform: rotatey(45deg) translatex(0px) translatey(0px);
	}

	&.open {
		width: 2*$cardWidth;
		height: 2*$cardHeight;
		transform: rotatey(-180deg) translatex(0px) translatey(0px);
	}
	
	.front-facing {
		transform: rotateY( 0deg ) translateZ( 2px );
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #f5fcfe;
		border: 2px white solid;
		border-radius: 5px;
		.abr {
			width: 100%;
			position: absolute;
			top: 40%;
			left: 0;
			margin: -35px 0 0 0;
			text-align: center;
			color: $colorMain;
			font-size: 40pt;
			font-family: 'Luckiest Guy';
			color: #fff;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-shadow:   0px -2px 0 #212121,  
						   0px -2px 0 #212121,
						   0px  2px 0 #212121,
						   0px  2px 0 #212121,
						  -2px  0px 0 #212121,  
						   2px  0px 0 #212121,
						  -2px  0px 0 #212121,
						   2px  0px 0 #212121,
						  -2px -2px 0 #212121,  
						   2px -2px 0 #212121,
						  -2px  2px 0 #212121,
						   2px  2px 0 #212121,
						  -2px  6px 0 #212121,
						   0px  6px 0 #212121,
						   6px  6px 0 #212121,
						   0 7px 1px rgba(0,0,0,.1),
						   0 0 2px rgba(0,0,0,.1),
						   0 2px 1px rgba(0,0,0,.3),
						   0 4px 2px rgba(0,0,0,.2),
						   0 6px 6px rgba(0,0,0,.25),
						   0 8px 8px rgba(0,0,0,.2),
						   0 12px 12px rgba(0,0,0,.15);
		}
		.title {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			text-transform: uppercase;
			font-size: 12px;
			margin: 15px 0 0 0;
			text-align: center;
			color: $colorMain;
            padding: 0 1rem;
		}
		.atomic-number {
			position: absolute;
			top: 10px;
			left: 10px;
			font-size: 12px;
			color: $colorMainDarker;
		}
		.atomic-mass {
			position: absolute;
			bottom: 10px;
			right: 10px;
			font-size: 12px;
			color: $colorMainDarker;
		}
	}

	@media (max-width: 768px) {
		width: 300px;
		height: 180px;

		&.open {
			width: 600px;
			height: 360px;
		}
	}
}

.container-cards {
	position: relative;
	width: 100%;
	max-width: $maxWidth;
	min-height: 100%;
	margin: 0px auto;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.button-engine-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #070909;
}

.button-engine {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 2px solid #9ea2a4;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    width: 30%;
}
@import '../../styles/branding.scss';


.navbar {
  background-color: $brand-5;
  width: 100%; // Full width of the grid column
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.menu-bars {
  font-size: $font-size-large;
  background: none;
  margin-left: 0;
  text-align: left;
  align-self: flex-start;
}

.nav-menu-mobile {
  // background-image: $mobile-menu-bg;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: $transition-slow;
  text-decoration: $text-decoration-none;
  z-index: 6;
}

.nav-menu-mobile.active {
  left: 0;
  transition: $transition-fast;
  text-decoration: $text-decoration-none;
}

.nav-menu {
  background-image: $gradient-navbar;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: $transition-slow;
  text-decoration: $text-decoration-none;
  box-shadow: $box-shadow;
  z-index: 6;
}

.nav-menu.active {
  left: 0;
  transition: $transition-fast;
  text-decoration: $text-decoration-none;
}

.nav-text, .nav-text-selected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  text-decoration: $text-decoration-none;
  width: 95%;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a, .nav-text-selected a {
  text-decoration: none;
  color: $nav-text-color;
  font-size: $nav-text-font-size;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  text-decoration: $text-decoration-none;
}

.nav-text a:hover, .nav-text-selected a:hover {
  background: $hover-1;
  text-decoration: $text-decoration-none;
  color: inherit;
}

.nav-text-selected.selected {
  background: $gradient-button-2;
  
  &:hover {
    background: $gradient-button-2;
  }
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: $text-decoration-none;
}

span {
  margin-left: 16px;
}

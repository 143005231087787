@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');
@import './styles/branding.scss';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

  // Color
  $brown: #2b2520;
  $green: #39dbc2;
  $pink: #f41fbf;
  $yellow: #ffed28;
  $purple: #b028ff;
  $blue: #49A7FF;
  $red: #ff3838;
  $orange: #db7953;
  $primary: #0ddfae;
  $secondary: #015ca7;
  $bg-primary: #066650;
  $bg-secondary: black;
  $bg-input:#011404;
  $brand-1:#abf8be;
  $shadow-second: rgba(26, 7, 82, 0.22);
  $background-1: rgb(250, 255, 249);

    // Size
  $extra-large: 110px;
  $large: 84px;
  $small: 24px;

body {
  width: 100%;
  height: 100%;
//   display: flex;
  align-items: center;
  justify-content: center;
//   background-color: white;
  // background: #485563;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to left, #29323c, #485563);  /* Chrome 10-25, Safari 5.1-6 */
//   background: linear-gradient(to bottom, #f6f8f9 0%, rgb(255, 255, 255) 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-attachment: fixed;
}

.desktop {
  display: flex;
  padding-top: 10%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.desktopLong {
  display: flex;
  // padding-top: 0px;
  height: 100vh;
  // align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.cb-title{
  font-size: 100pt;
  font-family: 'Luckiest Guy';
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:   0px -6px 0 #212121,  
                 0px -6px 0 #212121,
                 0px  6px 0 #212121,
                 0px  6px 0 #212121,
                -6px  0px 0 #212121,  
                 6px  0px 0 #212121,
                -6px  0px 0 #212121,
                 6px  0px 0 #212121,
                -6px -6px 0 #212121,  
                 6px -6px 0 #212121,
                -6px  6px 0 #212121,
                 6px  6px 0 #212121,
                -6px  18px 0 #212121,
                 0px  18px 0 #212121,
                 6px  18px 0 #212121,
                 0 19px 1px rgba(0,0,0,.1),
                 0 0 6px rgba(0,0,0,.1),
                 0 6px 3px rgba(0,0,0,.3),
                 0 12px 6px rgba(0,0,0,.2),
                 0 18px 18px rgba(0,0,0,.25),
                 0 24px 24px rgba(0,0,0,.2),
                 0 36px 36px rgba(0,0,0,.15);
}

.cb-title-mobile{
	font-size: 40pt;
	font-family: 'Luckiest Guy';
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow:0px -4px 0 #212121,  
				0px -3px 0 #212121,
				0px  3px 0 #212121,
				0px  3px 0 #212121,
				-3px  0px 0 #212121,  
				3px  0px 0 #212121,
				-3px  0px 0 #212121,
				3px  0px 0 #212121,
				-3px -3px 0 #212121,  
				3px -3px 0 #212121,
				-3px  3px 0 #212121,
				3px  3px 0 #212121,
				-3px  9px 0 #212121,
				0px  9px 0 #212121,
				3px  9px 0 #212121,
				0 10px 4px rgba(0,0,0,.1),
				0 0 1px rgba(0,0,0,.1),
				0 6px 1px rgba(0,0,0,.3),
				0 12px 4px rgba(0,0,0,.2),
				0 10px 2px rgba(0,0,0,.25),
				0 12px 23px rgba(0,0,0,.2),
				0 16px 16px rgba(0,0,0,.15);
  }

.sub-title{
  font-size: 30pt;
  font-family: 'Luckiest Guy';
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:   0px -2px 0 #212121,  
                 0px -2px 0 #212121,
                 0px  2px 0 #212121,
                 0px  2px 0 #212121,
                -2px  0px 0 #212121,  
                 2px  0px 0 #212121,
                -2px  0px 0 #212121,
                 2px  0px 0 #212121,
                -2px -2px 0 #212121,  
                 2px -2px 0 #212121,
                -2px  2px 0 #212121,
                 2px  2px 0 #212121,
                -2px  6px 0 #212121,
                 0px  6px 0 #212121,
                 6px  6px 0 #212121,
                 0 4px 1px rgba(0,0,0,.1),
                 0 0 2px rgba(0,0,0,.1),
                 0 2px 1px rgba(0,0,0,.3),
                 0 4px 2px rgba(0,0,0,.2),
                 0 4px 4px rgba(0,0,0,.25),
                 0 8px 8px rgba(0,0,0,.2),
                 0 8px 8px rgba(0,0,0,.15);
}

.sub-title-neon{
	font-size: 30pt;
	font-family: 'Luckiest Guy';
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow:   0px -2px 0 #212121,  
				   0px -2px 0 #212121,
				   0px  2px 0 #212121,
				   0px  2px 0 #212121,
				  -2px  0px 0 #212121,  
				   2px  0px 0 #212121,
				  -2px  0px 0 #212121,
				   2px  0px 0 #212121,
				  -2px -2px 0 #212121,  
				   2px -2px 0 #212121,
				  -2px  2px 0 #212121,
				   2px  2px 0 #212121,
				  -2px  6px 0 #212121,
				   0px  6px 0 #212121,
				   6px  6px 0 #212121,
				   0 4px 1px rgba(0,0,0,.1),
				   0 0 2px rgba(0,0,0,.1),
				   0 2px 1px rgba(0,0,0,.3),
				   0 4px 2px rgba(0,0,0,.2),
				   0 4px 4px rgba(0,0,0,.25),
				   0 8px 8px rgba(0,0,0,.2),
				   0 8px 8px rgba(0,0,0,.15);
	  // text-shadow: none;
	  transition: text-shadow 0.5s ease;
  }
  
  .sub-title-neon:hover {
	  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7cff01, 0 0 20px #7cff01, 0 0 25px #7cff01;
  }

.sub-title-neon-shadow {
	font-size: 30pt;
	font-family: 'Luckiest Guy';
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow: 
		0px -2px 0 #212121,  
		0px -2px 0 #212121,
		0px  2px 0 #212121,
		0px  2px 0 #212121,
		-2px  0px 0 #212121,  
		2px  0px 0 #212121,
		-2px  0px 0 #212121,
		2px  0px 0 #212121,
		-2px -2px 0 #212121,  
		2px -2px 0 #212121,
		-2px  2px 0 #212121,
		2px  2px 0 #212121,
		-2px  6px 0 #212121,
		0px  6px 0 #212121,
		6px  6px 0 #212121,
		0 4px 1px rgba(0,0,0,.1),
		0 0 2px rgba(0,0,0,.1),
		0 2px 1px rgba(0,0,0,.3),
		0 4px 2px rgba(0,0,0,.2),
		0 4px 4px rgba(0,0,0,.25),
		0 8px 8px rgba(0,0,0,.2),
		0 8px 8px rgba(0,0,0,.15),
		0 0 4px #fff, 
		0 0 8px #fff, 
		0 0 8px #7cff01, 
		0 0 10px #7cff01, 
		0 0 10px #7cff01,
		0 0 15px #7cff01, /* Second shadow */
		0 0 25px #7cff01; /* Second shadow */
}
  
.sub-title-mobile{
	font-size: 20pt;
	font-family: 'Luckiest Guy';
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow:   0px -2px 0 #212121,  
				   0px -2px 0 #212121,
				   0px  2px 0 #212121,
				   0px  2px 0 #212121,
				  -2px  0px 0 #212121,  
				   2px  0px 0 #212121,
				  -2px  0px 0 #212121,
				   2px  0px 0 #212121,
				  -2px -2px 0 #212121,  
				   2px -2px 0 #212121,
				  -2px  2px 0 #212121,
				   2px  2px 0 #212121,
				  -2px  6px 0 #212121,
				   0px  6px 0 #212121,
				   6px  6px 0 #212121,
				   0 7px 1px rgba(0,0,0,.1),
				   0 0 2px rgba(0,0,0,.1),
				   0 2px 1px rgba(0,0,0,.3),
				   0 4px 2px rgba(0,0,0,.2),
				   0 6px 6px rgba(0,0,0,.25),
				   0 8px 8px rgba(0,0,0,.2),
				   0 12px 12px rgba(0,0,0,.15);
  }

.main-image-box{
  height: 100% !important;
  width: 100% !important;
  
  color: #fff;
  background: linear-gradient(to bottom, #fff 0%, rgb(255, 255, 255) 100%);
  
}


.image-container{
	width: 100%;
	height: auto;
	mix-blend-mode: multiply; /* Choose a blend mode that suits your need */
	position: absolute;
	bottom: 0;
}

.player-card{
  height: 100% !important;
  width: 100% !important;
  color: #fff;
  background: linear-gradient(to bottom, #fff 0%, rgb(255, 255, 255) 100%);
}

.card-front{
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
	&:hover {
		box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff, 0 0 25px #fff;
	}
}

.card-front-selected{
	box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7cff01, 0 0 20px #7cff01, 0 0 25px #7cff01;
}

/// ---* BUTTONS *--- ///
.open-app-button{
	background: #000!important;
	border-color:#000 !important;
	border-radius: 20px !important;
	padding: 12px 36px!important;
	pointer-events: auto!important;
}

.button-raw {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.button-raw:focus, .button-raw.active {
    outline: none;
    box-shadow: none;
	color: rgb(255, 77, 0);
}

.button-text {
	font-family: 'Luckiest Guy';
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.threed-button {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	background-color: #f9f6f6;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	border-radius: 24px;
	box-shadow: 0px 5px 0px #0f120e;
	cursor: pointer;
	outline: none !important;
	transition: margin-right 2s, box-shadow 0.9s;
  }
  
  .threed-button:hover {
	transform: translateY(2px);
	box-shadow: 0px 5px 0px #0f120e;
  }
  
  .threed-button:active {
	background: rgb(215, 218, 214);
	transform: translate(0em, 1em);
	&::before {
	  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
	  transform: translate3d(0, 0, -1em);
	}
  }
 
	.threed-button-no-anim {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	background-color: #f9f6f6;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	border-radius: 24px;
	box-shadow: 0px 5px 0px #0f120e;
	cursor: pointer;
	outline: none !important;
	transition: margin-right 2s, box-shadow 0.9s;
	}

	.threed-button-no-anim:hover {
	transform: translateY(2px);
	box-shadow: 0px 5px 0px #0f120e;
	}

	.threed-button-no-anim:active {
	background: rgb(215, 218, 214);
	transform: translate(0em, 1em);
	&::before {
		box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
		transform: translate3d(0, 0, 0em);
	}
	}

// MOBILE 


.bg-input{
  background: #000803 !important;
}

.topright {
  position: absolute;
  right: 0;
  top: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/// CARD


$off_white:   #F8FAFF;
$bright_pink: #F4157E;
$dull_pink:   #CD106E;
$white: #fff;

*,
*::before,
*::after {
	box-sizing: border-box;
}



/*--------------------
container
--------------------*/
.wrap {
	margin: 150px auto 30px;
	position: relative;
	width: 460px;
	background: $white;
	border-radius: 15px;
	padding: 272px 45px 30px;
	box-shadow: 0 10px 40px hsla(0, 0, 0, 0.1);
}

/*--------------------
confirmation
--------------------*/
.confirmation {
	position: absolute;
	top: 96px;
	left: 0;
	right: 0;
	opacity: 0;
	transition: opacity 300ms ease 300ms;
	&.visible {
		opacity: 1;
	}
}

.all-good {
	display: block;
	margin: 40px auto 0;
	width: 60px;
}

.path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
	&.circle {
		-webkit-animation: dash 0.9s ease-in-out;
		animation: dash 0.9s ease-in-out;
	}
	&.line {
		stroke-dashoffset: 1000;
		-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
		animation: dash 0.9s 0.35s ease-in-out forwards;
	}
	&.check {
		stroke-dashoffset: -100;
		-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
		animation: dash-check 0.9s 0.35s ease-in-out forwards;
	}
}

p {
	text-align: center;
	margin: 20px 0 60px;
	font-size: 1.25em;
	&.success {
		color: #777;
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
/*--------------------
Buttons
--------------------*/
.button-cnt {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.primary-cta {
	  background: $bright_pink;
		border-radius: 10px;
	  box-shadow: 0 10px 60px -10px $dull_pink;
	  color: white;
		padding: 0 24px;
		pointer-events: auto;
	  width: 250px;
		z-index: 9;
		transition: all 150ms ease;
		will-change: transform, box-shadow, background;
		&.hidden {
			opacity: 0;
			pointer-events: none;
	}
}

.secondary-cta {
	  background: #066650;
		border-radius: 10px;
		padding: 0 24px;
		pointer-events: auto;
	  width: 150px;
		z-index: 9;
		transition: all 150ms ease;
		will-change: transform, box-shadow, background;
		position: relative;
		&--done {
			background: transparent;
			color: $bright_pink;
		}
		&--send {
			border:1px solid $bright_pink;
			box-shadow: 0 10px 60px -10px $dull_pink;
			color: $white;
		}
		&.hidden {
			opacity: 0;
			pointer-events: none;
	}
}


/*--------------------
Form
--------------------*/
.form {
	fieldset {
		border: none;
		padding: 10px 0;

		label {
			display: inline-block;
			color: #444;
			font-size: 12px;
			padding: 4px;
			margin: 5px;
		}

		input {
			background-color: $white;
			width: 100%;
			height: 38px;
			color: #000;
			padding: 8px;
			border-radius: 5px;
			font-size: 16px;
			outline: none !important;
			border: 1px solid hsla(0, 0, 0, 0.3);
			position: absolute;
			right: 24px;
			box-shadow: inset 0 1px 4px hsla(0, 0, 0, 0.2);
			&.input-card-info {
				display: inline-block;
				width: 300px;
				&--lg {
					height: 100px;
				}
			}

		}
		.select {
			position: relative;
			&::after {
				content: "";
				border-top: 8px solid #222;
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				position: absolute;
				z-index: 2;
				top: 14px;
				right: 10px;
				pointer-events: none;
			}
			select {
				appearance: none;
				position: absolute;
				padding: 0;
				border: none;
				width: 100%;
				outline: none !important;
				top: 6px;
				left: 6px;
				background: none;
				:-moz-focusring {
					color: transparent;
					text-shadow: 0 0 0 #000;
				}
			}
		}
	}
}

/*--------------------
Credit Card
--------------------*/
.gift-card-box {
	perspective: 1000;
	width: 400px;
	height: 280px;
	position: absolute;
	top: -112px;
	left: 50%;
	transform: translateX(-50%);

	.flip {
		transform: rotateY(180deg);
	}

	.front {
		// background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/341817/imitation_apple_design1.jpg');
		background-size: contain;
    background-color: #066650;
	}
	
	.front,
	.back {
		width: 400px;
		height: 250px;
		border-radius: 15px;
		backface-visibility: hidden;
		position: absolute;
		color: #fff;
		font-family: sans-serif;
		top: 0;
		left: 0;
		box-shadow: 0 1px 6px hsla(0, 0, 0, 0.3);
		&.back {
			background: linear-gradient(269.85deg, #066650 1.71%, #fff 99.93%);
		}
	}

	.card {
		transition: 600ms;
		transform-style: preserve-3d;
		position: relative;
		transition: all 1s ease-in-out;
		&.whoosh {
			animation: whooshAnimation 2400ms;
		}
	}

	@keyframes whooshAnimation {
		0% {
		}
		50% {
			transform: translateY(-150vh);
		}
		75% {
			transform: translateX(150vw);
		}
		100% {
		}
	}

	.front-logo-cnt {
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.font-logo {
		width: 300px;
	}

	.card-description {
		font-family: sans-serif;
		line-height: 1.4;
		text-align: center;
	}
	.front {
		z-index: 2;
		transform: rotateY(0deg);
	}
	.back {
		transform: rotateY(180deg);
	}
}


/// CARDS 

$maxWidth : 960px;
$cardWidth : 150px;
$cardHeight : 180px;

$colorMain : #000;
$colorMainDarker : #000;
$colorSecundary : #9b59b6;
$colorSecundaryDarker : #9b59b6;





	/// BUTTONS
	
	.button-1 {
		border-radius: 0.5rem;
		margin: 1rem 0;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.8rem 1rem;
		border-radius: 1rem;
		// box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
	  
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
	  }
	  
	.button-1:hover {
		box-shadow: 0 10px 15px $brand-1, 0 6px 4px $shadow-second;
		// background-color: rgba($brand-1, 0.2);
	}
	  
	.button-1:active {
		background: white;
		transform: translate(0em, 0.75em);
		&::before {
		  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
		  transform: translate3d(0, 0, -1em);
		}
	}

	.button-signIn {
		border-radius: 0.5rem;
		margin: 1rem 0;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.8rem 1.5rem;
		border-radius: 1rem;
		// box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
	  
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
		box-shadow: 0 10px 15px $brand-1, 0 6px 4px $shadow-second;
	  }
	  
	  .button-signIn:hover {
		box-shadow: 0 10px 15px $brand-1, 0 6px 4px $shadow-second;
		// background-color: rgba($brand-1, 0.2);
	  }
	  
	  .button-signIn:active {
		background: white;
		transform: translate(0em, 0.75em);
		&::before {
		  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
		  transform: translate3d(0, 0, -1em);
		}
	}


	  .button-formation-selected {
		border-radius: 0.5rem;
		margin: 1rem 0;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.4rem 1rem;
		border-radius: 1rem;
		box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
	  
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
		background: $brand-1;
	  }
	  
	  .button-formation {
		border-radius: 0.5rem;
		// margin: 0.3rem 0;
		background-color: transparent;
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.2rem 0.8rem;
		border-radius: 1rem;
		// box-shadow: shadow(low);
	
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1.1rem;
	  
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		// box-shadow: 0 2px 2px #fff, 0 2px 5px #fff, 0 2px 5px #000803, 0 2px 4px #000803, 0 2px 5px #000803
	  }
	
	  .button-formation:hover {
		box-shadow: 0 10px 15px $brand-1, 0 6px 4px $shadow-second;
		// background-color: rgba($brand-1, 0.2);
	  }
	  
	  .button-formation:active {
		background: white;
		// transform: translate(0em, 0.15em);
		&::before {
		  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
		  transform: translate3d(0, 0, -1em);
		}
	}

	.button-1-nopad {
		border-radius: 0.5rem;
		// margin: 1rem 0;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.4rem 0.6rem;
		border-radius: 1rem;
		// box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
	  
		// display: inline-flex;
		// justify-content: center;
		// align-items: center;
		outline: none !important;
	  }

	.button-1-nopad:hover {
		box-shadow: 0 10px 15px $brand-1, 0 6px 4px $shadow-second;
		// background-color: rgba($brand-1, 0.2);
	}
	  
	.button-1-nopad:active {
		background: white;
		// transform: translate(0em, 0.75em);
		&::before {
		  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
		  transform: translate3d(0, 0, -1em);
		}
	}

	.button-1-nopad-selected {
		border-radius: 0.5rem;
		// margin: 1rem 0;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.4rem 0.6rem;
		border-radius: 1rem;
		// box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
		background: $brand-1;
		// display: inline-flex;
		// justify-content: center;
		// align-items: center;
		outline: none !important;
	  }

	.button-bold{
		text-align: center;
		// font-size: 28px;
		font-weight: bold;
		color: #333;
		// text-transform: uppercase;
		letter-spacing: 3px;
		border-radius: 0.5rem;
		// margin: 1rem 0;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.4rem 0.8rem;
		border-radius: 1rem;
		// box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
	  
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
	}

	.button-1b{
		text-align: center;
		color: #333;
		border-radius: 0.5rem;
		background-color: rgb(250, 255, 249);
		box-shadow: 0 3px 10px rgba(0,0,0,0.12), 0 1px 10px rgba(0,0,0,0.24);
		transition: all 0.3s cubic-bezier(.25,.8,.25,1);
		
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding: 0.4rem 0.8rem;
		border-radius: 1rem;
		box-shadow: shadow(low);
		border: 1px solid var(--button-color);
		color: var(--button-text-color);
		font-size: 1rem;
	  
		display: inline-flex;
		justify-content: center;
		align-items: center;
		outline: none !important;
	}

	.button-1b:hover {
		box-shadow: 0 10px 25px $brand-1, 0 6px 4px $shadow-second;
		// background-color: rgba($brand-1, 0.2);
	  }
	  
	.button-1b:active {
		background: white;
		transform: translate(0em, 0.75em);
		&::before {
		  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
		  transform: translate3d(0, 0, -1em);
		}
	}

	.no-outline:focus {
		outline: none !important;
		box-shadow: none !important;
	}


	// TITLES

	.table-title {
		text-align: center;
		// font-size: 28px;
		// font-weight: bold;
		// color: #333;
		text-transform: uppercase;
		letter-spacing: 2px;
		padding: 10px 0;
		font-Family: 'luckiest guy';
		font-weight: '10';
		color: #17321a;
	}

	.table-title-light {
		text-align: center;
		// font-size: 28px;
		// font-weight: bold;
		// color: #333;
		text-transform: uppercase;
		letter-spacing: 3px;
		padding: 10px 0;
		font-Family: 'luckiest guy';
		font-weight: '10';
		color: #FFFFFF;
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}

	.table-title-gr {
		text-align: center;
		// font-size: 28px;
		// font-weight: bold;
		// color: #333;
		text-transform: uppercase;
		letter-spacing: 3px;
		padding: 10px 0;
		font-Family: 'luckiest guy';
		font-weight: '10';
		color: #57ef7c;
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}

	// *---- NAVBAR -----* //

	.navbar-anim {
		display: inline-block;
		padding: 10px 20px;
		font-size: 16px;
		background-color: #e74c3c;
		border: none;
		color: white;
		text-align: center;
		text-decoration: none;
		border-radius: 24px;
		cursor: pointer;
		transition: transform 0.3s, box-shadow 0.3s;
	  }
	  
	.navbar-anim:hover {
	transform: translateY(-2px);
	box-shadow: 0px 3px 0px #c0392b;
	}
	  
	.navbar-anim:active {
	transform: translateY(0px);
	box-shadow: 0px 1px 0px #c0392b;
	}

	.navbar-title {
		text-align: center;
		// font-size: 28px;
		// font-weight: bold;
		// color: #333;
		text-transform: uppercase;
		// letter-spacing: 3px;
		padding: 10px 0;
		font-Family: 'luckiest guy';
		font-weight: '10';
		color: #FFFFFF;
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}

	.bordered{
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}

	.comic-neue {
		// text-align: center;
		// font-size: 28px;
		font-weight: bold;
		// color: #333;
		// text-transform: uppercase;
		// letter-spacing: 3px;
		// padding: 10px 0;
		font-family: 'comic neue';
		font-weight: '400';
		color: #333;
	}

	.team-number{
		font-family: 'Luckiest Guy';
		margin-top: 5px;
		font-size: 1.5rem;
		color: #57ef7c;
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}

	.team-name{
		font-family: 'Luckiest Guy';
		margin-top: 5px;
		font-size: 1.1rem;
		color: #57ef7c;
		letter-spacing: 1.5px;
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}


	// COLUMN

	.col-centered {
		display: flex;
		justify-content: center;
		align-items: center;
  	}



.coupon-code-box {
	border: 2px solid #19ed0d;
	min-width: 600px;
	padding: 20px;
	border-radius: 1rem;
	text-align: center;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .coupon-code-box-mobile {
	border: 2px solid #19ed0d;
	padding: 20px;
	border-radius: 8px;
	text-align: center;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	max-width: fit-content;
	min-width: 100px;
  }
  
  .coupon-code-header {
	margin-bottom: 15px;
  }
  
  .coupon-code-header h2 {
	font-size: 0.8rem;
	color: #030303;
  }
  
  .coupon-code span {
	font-size: 0.9rem;
	font-weight: bold;
	color: #333;
	padding: 10px 0;
  }
  

  .coupon-code-buttons {
	border-radius: 0.5rem;
	margin: 1rem 0;
	background-color: rgb(250, 255, 249);
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
	padding: 0.8rem 1rem;
	border-radius: 1rem;
	// box-shadow: shadow(low);
	border: 1px solid var(--button-color);
	color: var(--button-text-color);
	font-size: 1rem;
  
	display: inline-flex;
	justify-content: center;
	align-items: center;
	outline: none !important;
  }
  
  .coupon-code-buttons:hover {
	box-shadow: 0 10px 15px $brand-1, 0 6px 4px $shadow-second;
	// background-color: rgba($brand-1, 0.2);
  }
  
  .coupon-code-buttons:active {
	background: white;
	transform: translate(0em, 0.75em);
	&::before {
	  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
	  transform: translate3d(0, 0, -1em);
	}
	}

// CONTAINER
  
.dashboard-top-container{
	max-width: 600px!important;
	min-height: 160px!important;
	margin: 10px auto;
	background-color: #ffffff;
	// border-radius: 1rem;
	box-shadow: 0 3px 8px rgba(0,0,0,0.12), 0 2px 6px rgba(0,0,0,0.24);
}

// MODALS

.rounded-modal .modal-content {
	border-radius: 30px; 
}

.modal-header button.close {
	outline: none !important;
	box-shadow: none !important;
}

// INPUTS

.sponsor-input {
	-moz-appearance: textfield;
	font: inherit;
	padding: .4em .4em;
	border-radius: .6em;
	border: 1px solid darkgray;
	padding-inline: 1em .9em;
	width: 26rem;
}

// MISC

.chat-bubble {
	position: relative;
	// width: 200px;
	// height: 150px;
	padding: 0px;
	background: 'rgb(250, 255, 249)';
	border-radius: 6px;
}
  
.chat-bubble:after {
    content: '';
    top: -15px;
    left: 12px;
    position: absolute;
    border: 0px solid;
    display: block;
    width: 18px;
    height: 10px;
    background-color: transparent;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: -12px 5px 0px 5px rgb(250, 255, 249);
}

.formation-legend {
    background-color: #fff;
    border: none;
    outline: none;
    display: inline-block;
    text-align: center;
	border-radius: 0.3rem;
}

.box-shadow-simple{
	box-shadow: 0 3px 8px rgba(0,0,0,0.12), 0 2px 6px rgba(0,0,0,0.24);
}

.box-shadow-simple-2{
    box-shadow: 0 4px 10px rgba(0,0,0,0.12), 0 3px 8px rgba(0,0,0,0.24);
}

.box-shadow-thick{
	box-shadow: 0 2px 2px #fff, 0 2px 5px #fff, 0 2px 5px #000803, 0 2px 4px #000803, 0 2px 5px #000803;
}

.box-shadow-thick-2{
    box-shadow: 0 1px 1px #fff, 0 1px 2px #fff, 0 1px 2px #000803, 0 1px 2px #000803, 0 1px 2px #000803;
}

.palette-1 {
    background-color: #B1EDE8;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus {
    background-color: transparent !important; /* Remove the blue background */
    color: inherit !important; /* Retain the original color */
    outline: none; /* Remove any outline */
}

.uniform-button-formation {
	min-width: 300px; /* or any desired width */
	max-height: 60px;
	width: 100%; /* Ensures the button takes the full width of its container */
	text-align: center;
	display: flex;
	justify-content: center;
  }
.player-view {

    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .player {

        display: flex;
        justify-content: center;

        position: absolute;
        border-radius: 50%;
        padding-top: 5%;
        width: 5%;

        font-size: 14px;

        &.clickable {
            cursor: pointer;
        }

        .number {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .name {
            position: absolute;
            bottom: -50%;
            width: max-content;
        }
    }
}

.player-image {
    width: 100%;
    height: 100%;
    // border-radius: 100%;
    object-fit: cover;
}

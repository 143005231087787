.login-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
    cursor: pointer;
    transition: border-color 0.3s ease;
  
    &:hover {
      border-color: #2c365d;
    }
  }
  
  /* Styles for the custom modal */
  .custom-modal {
    position: absolute; 
    top: 60px; 
    right: 0;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 1000;
  }
  
  .custom-modal-content {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    width: 250px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .modal-title {
    font-size: 1rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px !important;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
  
  .modal-content-row {
    margin: 15px 0;
  }
  
  .custom-logout-button {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .custom-logout-button:hover {
    background-color: #ff3333;
  }
  
  .profile-icon {
    color: #2c365d;
  }
  
  .profile-image:hover, .profile-icon:hover {
    border-color: #2c365d;
    cursor: pointer;
  }

  .notif {
    display: block;
    height: 35px;
    background: #E95950;
    color: #fff;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(233, 89, 80, 0.6));
    filter: drop-shadow(0px 0px 5px rgba(233, 89, 80, 0.6));
    position: absolute;
    border-radius: 7px;
    z-index: 1;
  }
  
  .notif:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #E95950;
    position: absolute;
    left: 25%;
    top: -7px;
    -webkit-transform: translateX(-65%);
    transform: translateX(-65%);
  }
  
  .notifItem {
    display: inline-block;
    color: #fff;
    font-family: sans-serif;
    font-weight: 600;
    padding: 8px 8px 0px 0px;
    margin: 0;
  }
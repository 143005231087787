@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy');

.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: inherit;
    background-image: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 8s;
    /* z-index: 1; */
    font-family: "Almendra", serif; 
  }
  
  .loadingScreen__board {
    padding: 64px;
    background-color: rgba(250, 255, 249, 0.805);
    border-radius: 24px;
    text-align: center;
    /* border: dashed 6px rgb(0 0 0 / 12%); */
    transition: opacity 0.4s;
  }


  .loadingScreen-title {
		text-align: center;
		// font-size: 28px;
		// font-weight: bold;
		// color: #333;
		text-transform: uppercase;
		// letter-spacing: 3px;
		padding: 10px 0;
		font-Family: 'luckiest guy';
		font-weight: '10';
		color: #FFFFFF;
		text-shadow: 
		-1px -1px 0 black,
		1px -1px 0 black,
		-1px 1px 0 black,
		1px 1px 0 black;
	}
  
  // .loadingScreen__button {
  //   padding: 8px 32px;
  //   background-color: rgba(0, 0, 0, 0.24);
  //   color: white;
  //   border: none;
  //   font-weight: bold;
  //   font-size: 2.5rem;
  //   border-radius: 8px;
  //   transition: background-color 0.4s;
  //   font-family: "Almendra", serif;
  // }

  .loadingScreen__button {
    // font-family: "Roboto", sans-serif;
    padding: 8px 32px;
		background-color: rgba(250, 255, 249, 0.908);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    // color: rgba(0, 0, 0, 0.676);
    // border: none;
    // font-weight: bold;
    // font-size: 2.5rem;
    // border-radius: 1rem;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }


  
  .loadingScreen__button:hover {
    background-color: rgba(0, 0, 0, 0.42);
    cursor: pointer;
  }
  
  .loadingScreen__button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  
  .loadingScreen__progress {
    position: absolute;
    bottom: 0;
    left: 0; 
    width: 100%; 
    height: 12px;
  }
  
  .loadingScreen__progress__value {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    transition: width 0.4s;
  }
  
  .loadingScreen--started {
    opacity: 0;
    pointer-events: none;
  }
  
  .loadingScreen--started .loadingScreen__board {
    opacity: 0;
  }
.notification-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #2196F3;
  color: white;
  padding: 15px 25px;
  border-radius: 4px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: rgba(144, 202, 249, 0.7);
  transform-origin: left;
}

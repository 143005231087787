.fadeOut {
  animation: puffOut 1s ease-out forwards;
  animation-delay: 0.5s;
}

@keyframes puffOut {
  0% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(2deg);
  }
  100% {
    opacity: 0;
    transform: scale(1.4) rotate(-2deg);
  }
}

.sparkle {
    position: absolute;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle, #beec14 0%, transparent 70%);
    border-radius: 50%;
    pointer-events: none;
    animation: sparkleAnim 1s ease-out forwards;
    z-index: 5;
  } 

  @keyframes sparkleAnim {
    0% {
      transform: scale(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: scale(1.5) rotate(180deg);
      opacity: 0;
    }
  }

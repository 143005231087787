// Define variables
// $brand-1: #e9eff5;
// $brand-2: #89c3fd;
// $hover-1: #89c3fd;
// $brand-3: rgb(104, 196, 250);
// $brand-4: #f3f520;
// $gradient-button: linear-gradient(17deg, $brand-1, $brand-2, $brand-3);
// $gradient-button-2: linear-gradient(17deg, #00ccff 0%, #0066ff 100%); 
// $gradient-navbar: linear-gradient(17deg, $brand-2, $brand-1, $brand-1);
$brand-1: #57ef7c;
$brand-2: #33cc33;
$brand-3: #00ccff;
$brand-4: #f9fb84;
$brand-5: #020826;
$brand-6: #15215f;
$gradient-button: linear-gradient(17deg, $brand-4, $brand-1, $brand-2); 
$gradient-button-2: linear-gradient(17deg, $brand-3, $brand-1, $brand-4); // used for buttons
$gradient-button-3: linear-gradient(17deg, $brand-3, $brand-1, $brand-4); // not used, keep for reference
$gradient-navbar: linear-gradient(45deg, $brand-5 0%, $brand-5 20%, $brand-5 100%);
$gradient-banner-account: $gradient-button-2;
$hover-1: $gradient-button-2; // used for navbar hover
$hover-2: #9df9b4;

.home-content-background {
  background: linear-gradient(to bottom, $brand-5 0%, $brand-5 60%, $brand-5 100%);
}

//*** - GREEN DAY 1 ***//
// $brand-1: #57ef7c;
// $brand-2: #33cc33;
// $hover-1: #9df9b4;
// $brand-3: rgb(104, 196, 250);
// $brand-4: #f3f520;
// $gradient-button: linear-gradient(17deg, #f3f520, #57ef7c, #33cc33);
// $gradient-button-2: linear-gradient(17deg, #00ccff 0%, #0066ff 100%);
// $gradient-navbar: linear-gradient(17deg, #f9fb84, #57ef7c, #57ef7c);

$transition-fast: 350ms;
$transition-slow: 850ms;
$text-decoration-none: none !important;
$font-size-large: 2rem;
$nav-text-color: black;
$nav-text-font-size: 18px;
$box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.5);

// Row Styles
.banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $gradient-navbar;
  min-width: 100vw;
  box-shadow: 0 4px 2px -2px black;
}

.banner-row-mobile {
  @extend .banner-row;
  padding-bottom: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.gradient-button {
    background: $gradient-button;
    border: none;
    border-radius: 12px;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none !important;
    margin: 1rem 0;
}

.gradient-button:hover {
transform: scale(1.05);
box-shadow: 0 26px 26px rgba(0, 0, 0, 0.15);
}

.gradient-button:active {
transform: scale(0.98);
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gradient-button-2 {
    background: $gradient-button-2;
    border: none;
    border-radius: 12px;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none !important;
    margin: 1rem 0;
}

.gradient-button-2:hover {
transform: scale(1.05);
box-shadow: 0 26px 26px rgba(0, 0, 0, 0.15);
}

.gradient-button-2:active {
transform: scale(0.98);
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gradient-button-no-margin {
z-index: 4;
background: $gradient-banner-account;
border: none;
border-radius: 12px;
color: white;
padding: 10px 20px;
font-size: 16px;
font-weight: bold;
text-align: center;
cursor: pointer;
transition: transform 0.2s, box-shadow 0.2s;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
outline: none !important;
margin: 0rem 0;
}

.gradient-button-no-margin:hover {
transform: scale(1.05);
box-shadow: 0 26px 26px rgba(0, 0, 0, 0.15);
}

.gradient-button-no-margin:active {
transform: scale(0.98);
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.gradient-button-play {
background: $gradient-button-2;
border: none;
border-radius: 12px;
color: white;
padding: 10px 20px;
font-size: 16px;
font-weight: bold;
text-align: center;
cursor: pointer;
transition: transform 0.2s, box-shadow 0.2s;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
outline: none !important;
margin: 1rem 0;
box-shadow: 0px 5px 0px #0f120e;
transition: margin-right 2s, box-shadow 0.9s;
}

.gradient-button-play:hover {
transform: scale(1.05);
box-shadow: 0px 5px 0px #0f120e;
}

.gradient-button-play:active {
background: rgb(215, 218, 214);
transform: translate(0em, 0.5em);
&::before {
  box-shadow: 0 0 0 2px $brand-1, 0 0 $brand-1;
  transform: translate3d(0, 0, -0.5em);
}
}
.custom-pagination a {
    color: #007bff; /* Change the link color */
  }
  
  .custom-pagination .page-item.active .page-link {
    color: #000101; /* Page link color */
    background-color: #f0f4f9; /* Active item background color */
    border-color: #070808; /* Active item border color */
  }
  
  .custom-pagination .page-link {
    color: #000101; /* Page link color */
    background-color: #fff; /* Page link background color */
    border: 1px solid #010102; /* Page link border color */
  }
  
  .custom-pagination .page-link:hover {
    color: #0056b3; /* Page link hover color */
  }
  